import * as React from "react";
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { Nav, Body, Footer } from "./App";
import { GreetingsFellowGoblin } from './GreetingsFellowGoblin';

const root = ReactDOM.createRoot(document.getElementById("cluttercore") as Element);

root.render(
    // <React.StrictMode>
        <ChakraProvider>
            <Router>
                <Routes>

                    {/**Anon */}
                    <Route path="/" element={[
                        <Nav/>, 
                        <Body children={[
                            <GreetingsFellowGoblin/>
                        ]}/>,
                        <Footer/>
                    ]}/>
                    <Route path="/clutter" element={[
                        <Nav/>, 
                        <Body children={[
                            <GreetingsFellowGoblin/>
                        ]}/>, 
                        <Footer/>
                    ]}/>

                    {/**Signed in */}
                    <Route path="/clutter/:user" element={[
                        <Nav/>,
                        <Body children={[
                            <Body children={[
                                <GreetingsFellowGoblin/>
                            ]}/>, 
                        ]}/>,
                        <Footer/>
                    ]} />

                </Routes>
            </Router>
        </ChakraProvider>
    // </React.StrictMode> 
);


// '{ user: {}; clutter: {}; permissions: {}; surveys: {}; hoard: {}; }': user, clutter, permissions, surveys, hoard