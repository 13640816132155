import { Button, Heading, Select, SimpleGrid, Box} from "@chakra-ui/react";
import { getClutter } from "./Functions";
import { useState, useEffect} from "react";

const styles: Object = {
    border:'6px solid white',      
    color:'white.500',
    fontWeight:'semibold',
    letterSpacing:'wide',
    fontSize:'xs',
    ml:'2'
};

console.log('rendered GreetingsFellowGoblin()');

export const GreetingsFellowGoblin = () => {

    const [clutter, setClutter]: [{fetching: Boolean, fetched: []}, any] = useState({fetching: true, fetched: []});

    useEffect(()=>{
        getClutter().then(c => setClutter(c));
    }, []);

    //error logging
    useEffect(()=>{
        const heading = "color: tomato; font-weight: bold;";
            console.log("%cc l u t t e r %c", heading, '\n', clutter.fetched);
    }, [(clutter.fetching !== true)]); // when true changes, trigger

    const NavParent = () => {
        
        {clutter.fetched.map((i: any) => {
            return (
                <li>{JSON.stringify(i.clutter)}</li>
            )
        })}
        return (
            <SimpleGrid minChildWidth='150px' spacing='1vw' width='100vw'>
                {clutter.fetched.map((i: any) => {
                    return (
                        <Box>
                            <Select placeholder={i.clutter} {...styles}
                            >
                                {i.fields.map((i: string) => {
                                    return <option value={i}>{i}</option>
                                })}
                            </Select>
                        </Box>
                    )
                })}   
            </SimpleGrid>
        );
    };

    return (<>    
        <NavParent/>
        <div className="container" style={{
            "width": "550px", 
            "margin": "0 auto" 
        }}>
            
            <br/>
            <Heading as="h1" size="lg">Cluttercore by IAS 👺</Heading>
            <br/>
            <Heading as="h2" size="md">The best of all social medias, all under your goblinish proprietary ownership.</Heading>
            <br/>
            <Heading as="h4" size="sm" style={{"color": "red"}}>🤔  Wait what do you mean by "goblinish"?!</Heading>  
            <br/>
            <Heading as="h4" size="sm">Specifically, tenju goblins (the 👺 emoji) are mythical beings that are thought to scare away evil spirits. Cluttercore will help protect you from targeted marketers and keep your hoard of social media clutter shinies safe in your control, as it should be.   </Heading>

            <br/>
            
            Current Clutter: <br/>
            {clutter.fetched.map((i: any) => {
                return (
                    <li>
                        {JSON.stringify(i.clutter)} 
                        {JSON.stringify(i.fields)}
                    </li>
                )
            })}
            <br/>
            <br/>
            <br/>
            <Button>
                Create Clutter Type 👺
            </Button>&nbsp;
            <Button>
                Add Clutter Entry ➕
            </Button>
        
            <br/><br/>
        
            <Button>
                Edit Clutter Type ✍️
            </Button>&nbsp;
            <Button>
                Edit Clutter Entry ✏️
            </Button>
        
            <br/>
            <br/>
        
            <Button>
                Import Clutter 💻
            </Button>&nbsp;
            <Button>
                Save Your Clutter 💻
            </Button>
            <br/><br/>
            <Button>
                Offer Entries on DataMarket 💹
            </Button>
        
        </div></>
    );
    
}