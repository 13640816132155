import react, { DOMElement, DOMAttributes, ReactNode } from 'react';

export let newElem: {
	div(n: string, child?: ReactNode): DOMElement<DOMAttributes<Element>, Element>;
} = {
	div(n: string, child?: ReactNode) {
		return react.createElement(n, {}, child)
	}
}

// const url = 'http://infoarchstudio:5079'; // TURN ON FOR DEV



//TURN ON FOR COMPILING STATIC FILES
const url = 'https://us-east-2.aws.data.mongodb-api.com/app/data-vskon/endpoint'; 
const headers = {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
	changeOrigin: true,
    headers: {
		"Access-Control-Allow-Origin": 'www.cluttercore.app',
      	"Content-Type": "application/json",
	  	"email": "helen@infoarch.studio",
	  	"password": "vWl46STdpm4L99vHZEjwCkifUl" 
    }
}; 
//ENDOF TURN ON FOR COMPILING STATIC FILES

export async function getClutter(): Promise<{fetching: Boolean; fetched: Object[];}> {
	var data: any = 
		await fetch(url + '/getClutter', headers)
				.then(res => {return res.json()}) // parsing body
				.then(d => {return {fetching: false, fetched: d.result}})
				// .finally(()=>console.log("fetch function complete"))
				.catch((e) => console.log(e));
	return data;
}
