import { useParams} from 'react-router-dom';
import { newElem } from './Functions';

export function Nav() {
	const { user } = useParams();
	const tag = (user? user : "anon") + "-nav";
	const root = newElem.div(tag);
	return root;
}

export function Body(props: any) {
	const { user } = useParams();
	const tag = (user? user : "anon") + "-body";
	const root = newElem.div(tag, props.children);
	// // debug
	// console.log(root._owner.child.stateNode);
	return root;
}

export function Footer() {
	const { user } = useParams();
	const tag = (user? user : "anon") + "-footer";
	const root = newElem.div(tag);
	return root;
}